.container {
  padding: 12px 0;
  border-bottom: 1px solid #d8d9da;
}

.title {
  cursor: pointer;
  display: flex;
  gap: 8px;
  margin-bottom: 10px;
}

.iconOpened {
  transition: ease-in-out 0.2s;
  transform: rotate(0);
}

.iconClosed {
  transition: ease-in-out 0.2s;
  transform: rotate(-90deg);
}

.titleWrapper {
  cursor: pointer;
  display: flex;
  gap: 8px;
  margin-bottom: 10px;
}
