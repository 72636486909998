.editContainer {
  width: 100%;
  height: 180px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.input {
  max-width: 560px;
  width: 100%;
}

.editButton {
  max-width: 220px;
  width: 100%;
}
.createContainer {
  width: 100%;
}

@media screen and (max-width: 1400px) {
  .editContainer {
    max-width: 560px;
    height: 100%;
  }
  .editButton {
    max-width: 560px;
  }
}
