.footer_container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 28px;
  font-weight: 700;
  color: #ffffff;
}

.linkBtn{
  height: auto;
  font-size: 28px;
  padding: 0 10px;
}