.navLink {
  margin-right: 16px;
}

.navLink:last-child {
  margin-right: 0px;
}

@media (max-width: 370px) {
  .navLink {
    font-size: 12px;
  }
}
