.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 14px 32px;
  border-bottom: 1px solid #828282;
  height: auto;
}

.container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.burgerButton {
  display: none;
}
.burgerItem {
  width: 100%;
  height: 2px;
  background: #ffffff;
  border-radius: 20px;
}

.burgerItem.active:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
  transition: ease-in-out 0.5s;
}
.burgerItem.active:nth-child(2) {
  transform: scale(0);
}
.burgerItem.active:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
  transition: ease-in-out 0.5s;
}

@media (max-width: 1439px) {
  .header {
    padding: 16px;
  }
}

@media (max-width: 1300px) {
  .header {
    height: auto;
    padding: 16px;
  }
  .container {
    justify-content: space-between;
    width: 100%;
    margin-right: 0px;
  }
}

@media (max-width: 1023px) {
  .container {
    display: none;
  }
  .burgerButton {
    width: 24px;
    height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    padding: 4px 0px;
    z-index: 4;
  }
}
