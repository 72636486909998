.container {
  gap: 42px;
}

.buttons {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  width: 100%;
  align-items: center;
}
