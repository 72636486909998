.centered {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.vertical {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.between {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card {
  max-width: 480px;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 40px 32px;
  gap: 24px;
  text-align: center;
  border-radius: 10px;
  background: linear-gradient(
    245.06deg,
    #494949 0.86%,
    #2f2f2f 48.57%,
    #333333 92.97%
  );
}
@media screen and (max-width: 576px) {
  .card {
    padding: 40px 16px;
  }
}
