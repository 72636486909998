.container {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
}

.logout {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
}

.link {
  display: flex;
  align-items: center;
  align-content: center;
  border-right: 1px solid #828282;
  padding-right: 16px;
  gap: 8px;
  justify-content: flex-end;
  overflow-wrap: break-word;
  max-width: 250px;
}

.userName {
  display: flex;
  gap: 6px;
  overflow: hidden;
}

.userNameText {
  margin: 0;
  color: #ffffff;
  height: auto;
  line-height: 1;
}

.userName.wrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  max-width: 160px;
}
