body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav {
  padding: 16px 32px;
  background-color: transparent;
}
nav a {
  margin-right: 16px;
  text-decoration: none;
  text-transform: uppercase;
  color: inherit;
}
nav a:hover {
  text-decoration: underline;
}
