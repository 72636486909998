.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

.titleContainer {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  gap: 16px;
}

.questions {
  display: grid;
  margin-top: 40px;
  gap: 16px;
  grid-template-columns: repeat(1, minmax(300px, 1fr));

  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, minmax(300px, 1fr));
  }
}

.topicButton {
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-wrap: wrap;
  white-space: normal;
  font-size: 12px;
  font-weight: 700;
  border: 2px solid #4f4f4f;

  @media (min-width: 760px) {
    font-size: 14px;
  }

  @media (min-width: 1024px) {
    font-size: 16px;
  }
}
