.container {
  max-width: 480px;
  width: 100%;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.uploadButton {
  color: #0f0f0f;
  background-color: #ffffff;
}

.deleteButton {
  max-width: 140px;
}

@media (max-width: 500px) {
  .container {
    flex-direction: column;
    gap: 20px;
  }
}
