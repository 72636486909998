.container {
  position: fixed;
  top: 0;
  right: -120%;
  transition: ease-in-out 0.5s;
  align-items: flex-end;
  z-index: 2;
}

.container.active {
  right: 0;
  transition: ease-in-out 0.5s;
}

.blur {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 50%;
  right: 100%;
  transform: scale(0);
  z-index: 3;
}

.blur.active {
  transform: scale(100%);
  transition: ease-in-out 0.5s;
  right: 0;
}

.innerContainer {
  width: 320px;
  background: linear-gradient(245.06deg, #494949 0.86%, #2F2F2F 48.57%, #333333 92.97%);
  padding: 12px 16px;
  position: relative;
  z-index: 4;
  align-items: flex-start;
}

.sidebarMenu.inBurger {
  border-inline-end: none !important;
  border-bottom: 1px solid #d8d9da;
  padding: 39px 0;
  width: 100%;
}

.headerControls {
  justify-content: space-between;
  padding: 24px 0;
  border-bottom: 1px solid #d8d9da;
  width: 100% !important;
}
.innerHeadeControls {
  border: none !important;
}
