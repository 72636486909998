.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.chartContainer {
  max-width: 100%;
  width: 100%;
  padding: 32px;
  border: 2px solid #e4e4e4;
  border-radius: 16px;
  overflow: auto;
}
.wrapper {
  min-width: 1024px;
}
.title {
  text-transform: capitalize;
}

::-webkit-scrollbar {
  width: 12px; /* ширина для вертикального скролла */
  height: 10px; /* высота для горизонтального скролла */
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #e4e4e4;
  border-radius: 24px;
}
