.layout {
  min-height: 100vh;
  padding: 64px 16px 0px 16px;
}

.content {
  display: flex;
  justify-content: center;
}

.wrapper {
  row-gap: 42px;
  max-width: 500px;
}

.papper {
  background: transparent;
  max-width: 480px;
}

.footer {
  display: flex;
  justify-content: flex-end;
}
