.title {
  margin-top: 78px;
  text-align: center;
  font-size: 40px;
  color: #ffffff;
}

.textContainer {
  max-width: 896px;
  display: flex;
  margin: 40px auto 0 auto;
  align-items: flex-start;
  justify-content: center;
  gap: 12px;
}

.text {
  color: #ffffff;
  font-size: 18px;
  line-height: 28spx;
}

.icon {
  font-size: 28px;
  color: #d31e25;
}
