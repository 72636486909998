.mainContainer {
  display: flex;
  z-index: 5;
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  transition: all 0.3s;
  padding: 10px;
  row-gap: 8px;
  max-width: 380px;
}
.mainContainer.empty {
  transform: translateY(100%);
  transform: scale(0);
}
