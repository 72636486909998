.layout {
  min-height: 100vh;
}

.content {
  padding: 32px;
}

.assistantContent {
  padding: 40px 120px;
}

#sider {
  min-height: 100%;
  padding: 48px 16px 48px 0px;
}

@media (max-width: 1023px) {
  #sider {
    display: none;
  }
}

@media (max-width: 650px) {
  .content {
    padding: 16px;
  }

  .assistantContent {
    padding: 20px;
  }
}
